<template>
    <div>
        <v-container
            id="sessions"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="6" cols="12" class="d-flex">
                        <v-toolbar-title class="headline"
                            >Engage</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedEngagePage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="engageItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort by"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 15rem"
                            clearable
                            @click:clear="table.options.sortBy = null"
                            v-model="table.options.sortBy"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 8rem; color: black !important"
                            clearable
                            @click:clear="table.pagination.perPage = 10"
                            v-model="table.pagination.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <!--                        <v-btn-->
                        <!--                            class="border-radius-25 px-5 mx-1"-->
                        <!--                            depressed-->
                        <!--                            rounded-->
                        <!--                            dark-->
                        <!--                            color="#2C423D"-->
                        <!--                            @click="openPrayerRequestFormDialog('add')"-->
                        <!--                            >Add Session</v-btn-->
                        <!--                        >-->
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 15rem"
                            color="secondary"
                            placeholder="Filter by User"
                            readonly
                            @click="isSearchDialogOpen = true"
                            clearable
                            @click:clear="
                                table.options.user = Object.assign(
                                    {},
                                    { id: null, name: null }
                                )
                            "
                            :value="table.options.user.name"
                        ></v-text-field>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            clearable
                            @click:clear="clearDateRanges"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col>
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.name="{ item }">
                                {{ formatName(item) }}
                            </template>

                            <template v-slot:item.date="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>

                            <template v-slot:item.prayer="{ item }">
                                {{ truncateText(item.prayer) }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#78756A20'"
                                        rounded
                                        @click="
                                            openPrayerRequestInformationDialog(
                                                item
                                            )
                                        "
                                    >
                                        <v-icon
                                            :color="hover ? '#fff' : '#78756A'"
                                            >mdi-fullscreen</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <engage-prayer-request-information-dialog
            :is-open.sync="prayerRequestInformationDialog.show"
            :data="prayerRequestInformationDialog.data"
            @close="prayerRequestInformationDialog.show = false"
            v-if="prayerRequestInformationDialog.show"
        ></engage-prayer-request-information-dialog>

        <custom-search-dialog
            :is-open.sync="isSearchDialogOpen"
            title="Search User"
            :action="getUsersAction"
            @onSelect="onSelectUser"
        >
            <template v-slot:text="{ item }">
                {{ item.prefix }} {{ item.first_name }} {{ item.last_name }}
            </template>
        </custom-search-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.dateFrom"
            :end-date.sync="table.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>
p
<script>
import Pagination from "../../../Core/Pagination.vue";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import dateUtility from "@/mixins/date";
// import { GET_GROUPS } from "@/store/modules/group";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import { GET_PRAYER_REQUESTS } from "@/store/modules/prayerRequest";
import helperUtility from "@/mixins/helper";
import EngagePrayerRequestInformationDialog from "@/components/MainView/Engage/PrayerRequests/components/PrayerRequestInformationDialog";
import { GET_USERS } from "@/store/modules/user";

export default {
    name: "ministry-prayer-requests",

    components: {
        EngagePrayerRequestInformationDialog,
        CustomSearchDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    dateFrom: null,
                    dateTo: null,
                    user: { id: null, name: null },
                    sortBy: "desc",
                },
                headers: [
                    {
                        text: "Name",
                        value: "name",
                        sortable: false,
                    },
                    {
                        text: "Date Uploaded",
                        value: "date",
                        sortable: false,
                    },
                    {
                        text: "Prayer Request",
                        value: "prayer",
                        sortable: false,
                    },
                    {
                        text: "Prayer Count",
                        value: "prayer_count",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            prayerRequestForm: { show: false, action: "", data: null },
            prayerRequestInformationDialog: { show: false, data: null },
            selectedEngagePage: "/engage/prayer-requests",
            engageItems: [
                {
                    text: "Sessions",
                    value: "/engage/sessions",
                },
                {
                    text: "Prayer Requests",
                    value: "/engage/prayer-requests",
                },
                {
                    text: "#TakeAMomentToPray",
                    value: "/engage/daily-devotions",
                },
                {
                    text: "Events",
                    value: "/engage/events-calendar",
                },
            ],
            show: false,
            isSearchDialogOpen: false,
            getUsersAction: GET_USERS,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getPrayerRequests();
            },
            deep: true,
        },
    },

    methods: {
        async getPrayerRequests() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
                userID: this.table.options.user.id,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_PRAYER_REQUESTS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getPrayerRequests();
        },

        async openPrayerRequestInformationDialog(data) {
            this.prayerRequestInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        openPrayerRequestFormDialog(action) {
            switch (action) {
                case "add":
                    this.prayerRequestForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.prayerRequestForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.prayerRequestInformationDialog.data
                        ),
                    };
                    this.prayerRequestInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.prayerRequestForm = { show: true, action, data: null };
                    break;
            }
        },

        clearDateRanges() {
            this.table.options.dateFrom = null;
            this.table.options.dateTo = null;
        },

        onOpenUpdateSessionForm({ data }) {
            this.prayerRequestInformationDialog = Object.assign(
                {},
                {
                    show: false,
                    data: Object.assign({}, data),
                }
            );
            this.openPrayerRequestFormDialog("edit");
        },

        formatDate(timestamp) {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(timestamp)
            );
        },

        truncateText(text) {
            return helperUtility.truncateText(text, 100);
        },

        changeView(to) {
            this.$router.push(to);
        },

        onSelectUser(user) {
            this.table.options.user = Object.assign(
                {},
                {
                    id: user.id,
                    name: this.formatName({ requested_by: user }),
                }
            );
        },

        formatName({ requested_by }) {
            const { prefix, first_name, last_name, suffix } = requested_by;

            return `${prefix} ${first_name} ${last_name} ${
                suffix || ""
            }`.trim();
        },

        async initiate() {
            this.selectedEngagePage = this.$route.path;
            await this.getPrayerRequests();
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
